// IconLoader.js
import React from 'react';

const IconLoader = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="250.000000pt"
    height="250.000000pt"
    viewBox="0 0 250.000000 250.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)" fill="rgba(0, 0, 0, 1)" stroke="none">
    <path d="M1060 2435 c-187 -30 -362 -98 -518 -202 -97 -65 -238 -199 -306
          -292 -147 -202 -236 -471 -236 -716 0 -245 89 -514 236 -716 68 -93 209 -227
          306 -292 501 -334 1165 -274 1588 142 149 146 241 293 305 483 85 255 85 511
          0 766 -64 190 -156 337 -305 483 -278 274 -685 405 -1070 344z m340 -395 c85
          -43 80 -24 80 -295 l0 -241 -54 -23 c-100 -41 -167 -124 -221 -276 -19 -52
          -91 -137 -136 -161 l-27 -13 -7 362 c-3 199 -5 416 -3 481 l3 119 55 29 c102
          53 227 60 310 18z m335 -195 c233 -136 275 -178 275 -276 0 -67 -3 -69 -122
          -69 l-77 0 -7 -35 c-11 -61 -6 -64 108 -67 l103 -3 3 -289 c3 -333 5 -322 -96
          -399 -80 -61 -301 -187 -327 -187 -3 0 -5 315 -5 700 0 385 4 700 8 700 5 0
          66 -34 137 -75z m-827 -372 l-3 -438 -208 -3 -208 -2 3 293 3 294 28 42 c15
          23 52 61 82 84 54 42 269 164 293 166 10 1 12 -89 10 -436z m572 -547 l0 -475
          -27 -19 c-51 -36 -113 -63 -168 -73 -45 -8 -63 -7 -115 11 -34 11 -77 31 -95
          43 l-35 22 0 158 0 157 -65 0 -65 0 0 -115 c0 -63 -3 -115 -6 -115 -24 0 -279
          164 -332 214 -43 39 -71 93 -78 150 l-7 45 284 3 c267 3 287 4 331 25 105 48
          143 98 224 292 35 84 94 144 147 150 4 1 7 -212 7 -473z"/>
    </g>
  </svg>
);

export default IconLoader;
